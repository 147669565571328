
import banner from '../images/reda2023-banner.jpg'

const RedaBanner = () => {
    return ( 
        <a href='https://reda2023.thinkmint.eu' className="redabanner-group">
            <img src={banner} alt="reda banner" className="reda-banner img-fluid" />
        </a>
     );
}
 
export default RedaBanner;