import Table from 'react-bootstrap/Table';

const Programme = () => {
    return (
        <section className='container-fluid programme'>
        <div className='container'>
            <h2 className='about-title'>
                Programme
            </h2>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>Time</th>
            <th>Activites</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>8:00am - 8:30am</td>
            <td>Arrival & Registration</td>
          </tr>
          <tr>
            <td>8.30am – 9.15am</td>
            <td>
              Exhibition Opens + the tour of International Freedom Centre Tour: Onsite tour of the new International  Freedom Centre on the grounds of This Present House</td>
          </tr>
          <tr>
            <td>9:15am - 9.30am</td>
            <td>Welcome, Opening Remarks, Protocols, Announcements- Pastor Jude </td>
          </tr>
          <tr>
            <td>9:30am - 9:50am</td>
            <td>
                <div className='table-title'>
                    Keynote 1
                </div>
                <div className='table-content'>
                Post Fuel subsidy - The economic outlook of Nigeria, key trends and prospects - Ugo
                </div>
            </td>
          </tr>
          <tr>
            <td>9:50am - 10:15am</td>
            <td>
            <div className='table-title'>
                Keynote 2
                </div>
                <div className='table-content'>
                Real Estate Opportunities and Challenges in a downturn economy -Udo
            </div>
            </td>
          </tr>
          <tr>
            <td>10:15am - 10:30am</td>
            <td>
            <div className='table-title'>
            Keynotes 1&2 - Q & A
            </div>
            </td>
          </tr>
          <tr>
            <td>10.30am - 11:00am</td>
            <td>
            <div className='table-title'>
                Plenary 1
            </div>
            <div className='table-content'>
            Real estate cycles - seasonal patterns and predictability - leading the way to a more diverse, equitable and inclusive future for the real estate industry - Nigeria in focus
            </div>
            </td>
          </tr>
          <tr>
            <td>11.00am - 11:15am</td>
            <td> Audience Q & A </td>
          </tr>
          <tr>
            <td>11:15am - 12:15pm</td>
            <td>Break & Networking & Exhibition tour</td>
          </tr>
          <tr>
            <td>12:15pm - 12.45pm</td>
            <td>Platinum Sponsor Address & Presentation </td>
          </tr>
          <tr>
            <td>12.45pm - 1:10pm</td>
            <td> 
            <div className='table-title'>
            Keynote 3
            </div>
            <div className='table-content'>
            Solving the housing conundrum Land and property management
            </div>
            </td>
          </tr>
          <tr>
            <td>1:10pm - 1:25pm </td>
            <td>
            <div className='table-title'>
            Platinum sponsor & Keynote 3  Q & A 
            </div>
            </td>
          </tr>
          <tr>
            <td>1:25pm - 1:55pm</td>
            <td>
              <div className='table-title'>
              Plenary 2
              </div>
              <div className='table-content'>
              Global Real Estate Markets Investments- a discussion on yields and returns
              </div>
            </td>
          </tr>
          <tr>
            <td>1:55pm - 2:05pm</td>
            <td>Q & A</td>
          </tr>
          <tr>
            <td>2:05pm - 2:15pm</td>
            <td>Closing Remarks & Vote of Thanks</td>
          </tr>
          <tr>
            <td>2:15pm - 3:00pm</td>
            <td>Exhibition! Exhibition!! Exhibition!!!</td>
          </tr>
          <tr>
            <td>3.00pm</td>
            <td>Close</td>
          </tr>

        </tbody>
        </Table>
        </div>
        </section>
    );
}
 
export default Programme;