import SpeakersCard from "./SpeakersCard";
import speakers from "../data/speakersdata";

const Speakers = () => {
    const speakerData = speakers.map((speaker)=>{
        return <
            SpeakersCard
            name = { speaker.name }
            position = { speaker.position }
            company = { speaker.company }
            imageUrl = {speaker.imageUrl}
            />
        });
    
        return ( 
            <section className="container-fluid speakers" id='speakers'>
                <div className="container">
                <h2 className="pastspeaker-title"> 
                2023 Speakers
                </h2>
                <div className="speaker-group">
                {speakerData}
                </div>
                </div>
            </section>
        );
}
 
export default Speakers;