import repsPartners from '../images/reps-partners.png' 

const partners = () => {
    return ( 
        <div className="partners-group">
            <img src={repsPartners} alt="reps partners" className="img-fluid" /> 
        </div>
     );
}
 
export default partners;