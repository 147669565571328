const speakers = [
    {
        id : 1,
        name : "Ugodre Obi-Ochukwu​",
        position: "Founder and Partner",
        company: "Nairametrics Financial Associates",
        imageUrl: "ugodre-obi.jpg",
    },
    {
        id : 2,
        name : "Udo Okonjo",
        position: "CEO/Executive Vice Chair",
        company: "Fine and Country West Africa",
        imageUrl: "udo-okonjo.jpg",
    },
    {
        id : 3,
        name : "Akin Opatola",
        position: "Head of Brokerage",
        company: "Olawale Jordan Company",
        imageUrl: "akin-opatola.jpg",
    },
    {
        id : 4,
        name : "Tosin Ajose",
        position: "Lead Advisor",
        company: "DealHQ Partners",
        imageUrl: "tosin-ajose.jpg",
    },
    {
        id : 5,
        name : "Grace Ofure Ibhakhomu",
        position: "CEO",
        company: "Lifecard Investment Company",
        imageUrl: "grace-ofure.jpg",
    },
    {
        id : 6,
        name : "Ewaen Sorae",
        position: "Chief Principal Designer",
        company: "E’Sorae Homes",
        imageUrl: "ewaen-sorae.jpg",
    },
    {
        id : 7,
        name : "Olubunmi Ebhomenye",
        position: "Director",
        company: "Talent Academy Flour Mills Nigeria Plc",
        imageUrl: "olubunmi-ebhomenye.jpg",
    },
    {
        id : 8,
        name : "Tunde Balogun",
        position: "CEO",
        company: "Rent Small-Small",
        imageUrl: "tunde-balogun.jpg",
    },
    {
        id : 9,
        name : "Jide Adekola",
        position: "Executive Director M.E.D (Arc)",
        company: "MD/CEO Homework Development and Properties",
        imageUrl: "jide-adekola.jpg",
    },
    {
        id : 10,
        name : "Ayodeji Odeleye",
        position: "Managing Partner",
        company: "Biodun Odeleye & Co",
        imageUrl: "ayodeji-odeleye.jpg",
    },


]

export default speakers